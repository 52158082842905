import React, { useState, useEffect } from 'react';
import { getUsernameDetails } from '../apis/fetch';
import { useUser } from '../auth';
import { Link } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AccountDetails = () => {
  const user = useUser(); // Fetch the user details
  const [username, setUsername] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user || !user.id) return; // Wait for user data to be available

    const usernameURL = `${API_BASE_URL}/api/users/${user.id}/`; // Construct the URL
    let isMounted = true; // Handle unmounting

    const fetchData = async () => {
      try {
        const data = await getUsernameDetails(usernameURL); // Fetch username details
        if (isMounted) setUsername(data); // Update state if still mounted
      } catch (err) {
        if (isMounted) setError(err.message);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup function
    };
  }, [user]); // Re-run when `user` changes

  return (
    <div>
      <h1>Account Details</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message if any */}
      <Link to="/account/edit/details">Edit Account Details</Link>
      <table border="1" className="table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{username.username || 'N/A'}</td>
            <td>{username.name || 'N/A'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AccountDetails;
