import { useState } from 'react'
import FormErrors from '../components/FormErrors'
import { login } from '../lib/allauth'
import { Link } from 'react-router-dom'
import { useConfig } from '../auth'
import ProviderList from '../socialaccount/ProviderList'
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA library
import Button from '../components/Button'
import WebAuthnLoginButton from '../mfa/WebAuthnLoginButton'

export default function Login () {
  const [username, setUsername] = useState(''); // Either email or username
  const [password, setPassword] = useState('')
  const [recaptchaToken, setRecaptchaToken] = useState(null); // reCAPTCHA token
  const [response, setResponse] = useState({ fetching: false, content: null })
  const googleAPI = process.env.REACT_APP_API_GOOGLE_CAP;
  const config = useConfig()
  const hasProviders = config.data.socialaccount?.providers?.length > 0

  function submit () {
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA verification.');
      return;
    }
    setResponse({ ...response, fetching: true })
    login({ username, password, recaptchaToken}).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }
  return (
    <div>
      <h1>Login</h1>
      <p>
        No account? <Link to='/account/signup'>Sign up here.</Link>
      </p>

      <FormErrors errors={response.content?.errors} />

      <div><label>Username <input value={username} onChange={(e) => setUsername(e.target.value)} type='username' required /></label>
        <FormErrors param='username' errors={response.content?.errors} />
      </div>
      <div><label>Password: <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' required /></label>
        <Link to='/account/password/reset'>Forgot your password?</Link>
        <FormErrors param='password' errors={response.content?.errors} />
      </div>
      <div>
      <ReCAPTCHA sitekey={googleAPI} onChange={(token) => setRecaptchaToken(token)}></ReCAPTCHA>
      </div>
      
      <Button disabled={response.fetching} onClick={() => submit()}>Login</Button>
      {config.data.account.login_by_code_enabled
        ? <Link className='btn btn-secondary' to='/account/login/code'>Mail me a sign-in code</Link>
        : null}
      <WebAuthnLoginButton>Sign in with a passkey</WebAuthnLoginButton>
      
      {hasProviders
        ? <>
          <h2>Or use a third-party</h2>
          <ProviderList callbackURL='/account/provider/callback' />
        </>
        : null}
    </div>
  )
}
