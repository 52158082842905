import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser, useConfig } from "./auth";
import styles from "./Sidebar.module.css";

function NavBarItem({ href, to, icon, name }) {
  return (
    <li className={styles.navItem}>
      {href ? (
        <a className={styles.navLink} href={href}>
          <span className={styles.icon}>{icon}</span>
          <span className={styles.label}>{name}</span>
        </a>
      ) : (
        <Link className={styles.navLink} to={to}>
          <span className={styles.icon}>{icon}</span>
          <span className={styles.label}>{name}</span>
        </Link>
      )}
    </li>
  );
}

export default function Sidebar({ setContentShift }) {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation(); // For breadcrumbs
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown menu state
  const user = useUser();
  const config = useConfig();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setContentShift(!isOpen ? 250 : 0); // Adjust content margin dynamically
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
  };

  // Breadcrumb logic: generate crumbs from the current URL path
  const breadcrumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb)
    .map((crumb, index, array) => (
      <span key={crumb}>
        <Link to={`/${array.slice(0, index + 1).join("/")}`}>{crumb}</Link>
        {index < array.length - 1 && " / "}
      </span>
    ));

  return (
    <div className={styles.sidebarContainer}>
      {/* Top Bar */}
      <div className={`${styles.topBar} ${isOpen ? styles.open : styles.closed}`}>
        <Link to="/" className={`${styles.brand} ${isOpen ? "" : styles.closed}`}>
          🦊 {/* Fox icon for home page */}
        </Link>
        <button
          className={styles.toggleButton}
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          🍔 {/* Hamburger menu icon */}
        </button>
        {user && (
  <div className={styles.userMenu}>
    <button
      onClick={toggleDropdown}
      className={styles.userMenuButton}
      aria-label="User Menu"
    >
      🧙‍♂️
    </button>
    {isDropdownOpen && (
      <div className={styles.dropdownMenu}>
        {/* Username Section */}
        <div className={styles.userDetails}>
          <p className={styles.userName}>{user.display}</p>
          <p className={styles.userEmail}>{user.email}</p>
        </div>
        <hr className={styles.divider} /> {/* Divider */}
        {/* Menu Items */}
        <ul className={styles.navList}>
          <NavBarItem
            to={`account/details`}
            icon="🔧"
            name="Manage Account"
          />
          {config.data.usersessions && (
            <NavBarItem to="account/sessions" icon="🚀" name="Sessions" />
          )}
          <NavBarItem
            to={`/account/logout/`}
            icon="👋"
            name="Sign Out"
          />
        </ul>
      </div>
    )}
  </div>
)}

      </div>

      {/* Sidebar */}
      <nav className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
        <ul className={styles.navList}>
          {window.DEVELOPMENT && (
            <NavBarItem href={`${API_BASE_URL}`} icon="✉️" name="MailCatcher" />
          )}
          {user ? (
            <>
              <NavBarItem to="/dashboard" icon="📈" name="Dashboard" />
              <NavBarItem to="/category" icon="🍻" name="Drinks" />
              <NavBarItem to='/account/email' icon='📬' name='Change Email' />
              <NavBarItem to='/account/password/change' icon='🔒' name='Change Password' />
              {config.data.socialaccount
                    ? <NavBarItem to='/account/providers' icon='👤' name='Providers' />
                    : null}
              {config.data.mfa
                    ? <NavBarItem to='/account/2fa' icon='📱' name='Two-Factor Authentication' />
                    : null}

            </>
          ) : (
            <>
              <NavBarItem to={`/account/login/`} icon="🔑" name="Login" />
              <NavBarItem to={`/account/signup/`} icon="🧑" name="Signup" />
              <NavBarItem to={`/account/password/reset/`} icon="🔓" name="Reset Password" />
            </>
          )}
        </ul>
      </nav>

      {/* Breadcrumb Bar */}
      <div
        className={styles.breadcrumbBar}
        style={{ marginLeft: isOpen ? "250px" : "0" }} // Move breadcrumb bar dynamically
      >
        <div className={styles.breadcrumbContainer}>
          {/* Conditionally render the breadcrumb hamburger button */}
          {!isOpen && (
            <button
              className={styles.breadcrumbToggleButton}
              onClick={toggleSidebar}
              aria-label="Toggle Sidebar"
            >
              🍔
            </button>
          )}
          <div className={styles.breadcrumbs}>
            <Link to="/">Home</Link>
            {breadcrumbs.length > 0 && " / "}
            {breadcrumbs}
          </div>
        </div>
      </div>
    </div>
  );
}
