import React, { useState, useEffect } from 'react';
import { useUser } from '../auth';
import { getCategories } from '../apis/fetch';
import { Link } from 'react-router-dom';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const user = useUser();

  useEffect(() => {
    let isMounted = true; // To handle potential component unmounting during async calls
    const fetchData = async () => {
      try {
        const data = await getCategories(); // Fetch categories data
        if (isMounted) setCategories(data); // Update state only if component is still mounted
      } catch (err) {
        if (isMounted) setError(err.message);
      }
    };

    fetchData(); // Trigger data fetch when the component mounts

    return () => {
      isMounted = false; // Cleanup function to prevent state updates on unmounted components
    };
  }, []);

  return (
    <div>
      <h1>Categories</h1>
      <Link to="/category/create/">Create New Category</Link>
      {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message if any */}
      <table border="1" className="table">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Creator</th>
            <th>Drinks</th>
          </tr>
        </thead>
        <tbody>
          {categories.length > 0 ? (
            categories.map((category) => (
              <tr key={category.id}>
                <td>{category.category_name}</td>
                <td>{user.display}</td>
                <td>
                  {/* Link to a new page that lists all drinks in the category */}
                  <Link to={`/category/drinks/${category.id}`}>View All Drinks</Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No categories found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Categories;
