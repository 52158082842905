import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // For getting category id from the URL
import { getCategories } from '../apis/fetch'; // Import the global fetch function

const CategoryDrinks = () => {
  const [category, setCategory] = useState(null);
  const [error, setError] = useState(null);
  const { categoryId } = useParams(); // Get the category ID from the URL

  useEffect(() => {
    async function fetchCategory() {
      try {
        // Fetch the category by ID from your API
        const data = await getCategories(); // Assuming getCategories can return all categories
        const foundCategory = data.find((cat) => cat.id === parseInt(categoryId)); // Find the category by ID
        setCategory(foundCategory);  // Set the found category to state
      } catch (err) {
        setError(err.message);  // Set error if there's any
      }
    }

    fetchCategory(); // Fetch the category details when the component mounts
  }, [categoryId]); // Re-fetch when categoryId changes

  return (
    <div>
      <h1>{category ? category.category_name : 'Loading Category...'}</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display error message if any */}
      <h3>Drinks:</h3>
      <ul>
        {category && category.drinks.length > 0 ? (
          category.drinks.map((drink, i) => (
            <li key={i}>{drink.drink_name}</li>
          ))
        ) : (
          <li>No drinks available for this category.</li>
        )}
      </ul>
    </div>
  );
};

export default CategoryDrinks;
