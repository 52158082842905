import { useUser } from './auth'
import React from "react";
import styles from "./Dashboard.module.css";

export default function Dashboard() {
  const cards = [
    { title: "Revenue", value: "$12,345", icon: "💵", color: "#4caf50" },
    { title: "Users", value: "1,234", icon: "👤", color: "#2196f3" },
    { title: "Orders", value: "456", icon: "📦", color: "#ff9800" },
    { title: "Feedback", value: "89%", icon: "⭐", color: "#f44336" },
  ];
  const user = useUser()
  return (
    
    <div className={styles.dashboardContainer}>
  
      <h1 className={styles.dashboardTitle}>Welcome to Your Dashboard {user.display}!</h1>

      <div className={styles.cardGrid}>
        {cards.map((card, index) => (
          <div
            key={index}
            className={styles.card}
            style={{ borderColor: card.color }}
          >
            <div className={styles.cardIcon} style={{ color: card.color }}>
              {card.icon}
            </div>
            <div className={styles.cardContent}>
              <h2 className={styles.cardTitle}>{card.title}</h2>
              <p className={styles.cardValue}>{card.value}</p>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.tableContainer}>
        <h2 className={styles.sectionTitle}>Recent Activity</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Jan 18, 2025</td>
              <td>User Signup</td>
              <td className={styles.success}>Completed</td>
            </tr>
            <tr>
              <td>Jan 17, 2025</td>
              <td>Order Placed</td>
              <td className={styles.warning}>Pending</td>
            </tr>
            <tr>
              <td>Jan 16, 2025</td>
              <td>Feedback Received</td>
              <td className={styles.error}>Delayed</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
