import React, { useState } from 'react';
import { postCategory } from '../apis/fetch'; // Import the global function
import { useUser } from "../auth"; // Import useUser to get the logged-in user's data
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import Button from '../components/Button';
import FormErrors from '../components/FormErrors';

const CreateCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const user = useUser(); // Get the logged-in user
  const [response, setResponse] = useState({ fetching: false, content: null });
  const navigate = useNavigate(); // Initialize the navigate hook

  const submit = async () => {
    setResponse({ ...response, fetching: true });

    // Pass category name and user ID (creator) in the request
    const categoryData = {
      category_name: categoryName,
      creator: user.id, // Set the creator as the logged-in user's ID
    };

    try {
      const content = await postCategory(categoryData);
      setResponse((r) => ({ ...r, content }));
      navigate("/category"); // Navigate to /category after successful submission
    } catch (e) {
      console.error(e);
      window.alert(e);
    } finally {
      setResponse((r) => ({ ...r, fetching: false }));
    }
  };

  return (
    <div>
      <h1>Create Category</h1>
      <div>
        <label>
          Category Name
          <input
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            type="text"
            required
          />
        </label>
        <FormErrors errors={response.content?.errors} />
      </div>
      
      <Button disabled={response.fetching} onClick={submit}>
        Submit
      </Button>
    </div>
  );
};

export default CreateCategory;
