import React, { useState } from 'react';
import { postCategory } from '../apis/fetch'; // Import the global function
import { useUser } from "../auth"; // Import useUser to get the logged-in user's data
import Button from '../components/Button';
import FormErrors from '../components/FormErrors'
  
const CreateCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const user= useUser(); // Get the logged-in user
  const [response, setResponse] = useState({ fetching: false, content: null })

  function submit () {
    setResponse({ ...response, fetching: true })
    // Pass category name and user ID (creator) in the request
    const categoryData = {
        category_name: categoryName,
        creator: user.id, // Set the creator as the logged-in user's ID
      };
  
      postCategory(categoryData).then((content) => {
      setResponse((r) => { return { ...r, content } })
    }).catch((e) => {
      console.error(e)
      window.alert(e)
    }).then(() => {
      setResponse((r) => { return { ...r, fetching: false } })
    })
  }

  return (
    <div>
      <h1>Create Category</h1>
      <div><label>Category Name <input value={categoryName} onChange={(e) => setCategoryName(e.target.value)} type='text' required /></label>
        <FormErrors errors={response.content?.errors} />
      </div>
  
        {/* Creator ID is automatically set, so we no longer need an input for it */}
        <div><label>Creator ID <input value={user.id} onChange={(e) => user(e.target.value)} type='number' required /></label>
        <FormErrors errors={response.content?.errors} />
      </div>

      <div><label>Creator EMAIL <input value={user.email} onChange={(e) => user(e.target.value)} type='string' required /></label>
        <FormErrors errors={response.content?.errors} />
      </div>
       
       
        <Button disabled={response.fetching} onClick={() => submit()}>Submit</Button>
    </div>
  );
};

export default CreateCategory;
