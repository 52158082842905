import React, { useState, useEffect } from 'react';
import { getCategories } from '../apis/fetch'; // Import the global fetch function
import { Link } from 'react-router-dom'; // Import Link for navigation

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getCategories();  // Call the global fetch function
        setCategories(data);  // Set the fetched data to state
      } catch (err) {
        setError(err.message);  // Set error if there's any
      }
    }

    fetchData(); // Fetch data when the component mounts
  }, []); // This will only run once, after the component mounts

  return (
    <div>
      <h1>Categories</h1>
      <Link to={'/category/create/'}>
                  Create New Category
                </Link>
      {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Display error message if any */}
      <table border="1" className="table">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Creator</th>
            <th>Drinks</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, i) => (
            <tr key={i}>
              <td>{category.category_name}</td>
              <td>{category.creator_email}</td>
              <td>
                {/* Link to a new page that lists all drinks in the category */}
                <Link to={`/category/drinks/${category.id}`}>
                  View All Drinks
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Categories;
