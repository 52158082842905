import axios from 'axios';
import { getCSRFToken } from '../lib/django';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const URLs = Object.freeze({
  CATEGORIES: API_BASE_URL + '/api/categories/',
  DRINKS: API_BASE_URL + '/api/drinks/',
});

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Include cookies in requests (if required)
});

// Generic function to handle API requests
async function fetchData(url, method = 'GET', body = null) {
  try {
    const options = {
      url,
      method,
      data: body,
      headers: {
        'X-CSRFToken': getCSRFToken (), // Include the CSRF token in headers
      },
    };

    const response = await axiosInstance(options);
    return response.data; // Return data to the caller
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.detail || error.response.statusText
      : error.message;
    throw new Error('Error: ' + errorMessage);
  }
}

// Function to get categories
export async function getCategories() {
  const data = await fetchData(URLs.CATEGORIES);
  return data; // Return data to the caller
}

// Function to get drinks
export async function getDrinks() {
  const data = await fetchData(URLs.DRINKS);
  return data; // Return data to the caller
}

// Function to post a new category
export async function postCategory(categoryData) {
  const data = await fetchData(URLs.CATEGORIES, 'POST', categoryData);
  return data; // Return the created category data
}
