import { useState } from 'react';
import * as allauth from '../lib/allauth';
import { Navigate, useLoaderData } from 'react-router-dom';
import FormErrors from '../components/FormErrors';
import Button from '../components/Button';
import { QRCodeCanvas } from 'qrcode.react'; // Import QR code component

export async function loader({ params }) {
  const resp = await allauth.getTOTPAuthenticator();
  return { totp: resp };
}

export default function ActivateTOTP(props) {
  const { totp } = useLoaderData();
  const [code, setCode] = useState('');
  const [response, setResponse] = useState({ fetching: false, content: null });

  function submit() {
    setResponse({ ...response, fetching: true });
    allauth.activateTOTPAuthenticator(code)
      .then((content) => {
        setResponse((r) => ({ ...r, content }));
      })
      .catch((e) => {
        console.error(e);
        window.alert(e);
      })
      .then(() => {
        setResponse((r) => ({ ...r, fetching: false }));
      });
  }

  if (totp.status === 200 || response.content?.status === 200) {
    return <Navigate to="/account/2fa" />;
  }

  const totpSecret = totp.meta?.secret;

  return (
    <section>
      <h1>Activate TOTP</h1>

      <div>
        <label>
          Authenticator secret:
          <input disabled type="text" value={totpSecret || ''} />
          <span>You can store this secret and use it to reinstall your authenticator app at a later time.</span>
        </label>
      </div>

      <div>
        {totpSecret && (
          <div>
            <p>Scan this QR code in your authenticator app:</p>
            <QRCodeCanvas
              value={`otpauth://totp/MyAppName?secret=${totpSecret}&issuer=MyAppName`}
              size={256} // QR code size
            />
          </div>
        )}
      </div>

      <div>
        <label>
          Authenticator code:
          <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
        </label>
        <FormErrors param="code" errors={response.content?.errors} />
        <FormErrors errors={totp.errors} />
      </div>

      <Button onClick={() => submit()}>Activate</Button>
    </section>
  );
}
