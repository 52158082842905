import { useState, useEffect } from 'react'
import { useConfig } from '../auth'
import * as allauth from '../lib/allauth'

export default function Sessions() {
  const config = useConfig()
  const [sessions, setSessions] = useState([])
  console.log(sessions)
  useEffect(() => {
    allauth.getSessions().then((resp) => {
      if (resp.status === 200) {
        setSessions(resp.data)
      }
    })
  }, [])

  return (
    <div>
      <h1>Sessions</h1>

      <table className='table table-bordered'>
        <thead>
          <tr>
            <th>Started At</th>
            <th>IP Address</th>
            <th>Browser</th>
            {config.data.usersessions.track_activity ? <th>Last Seen At</th> : null}
            <th>Current</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session, i) => (
            <tr key={i}>
              <td>{new Date(session.created_at * 1000).toLocaleString()}</td>
              <td>{session.ip}</td>
              <td>{session.user_agent}</td>
              {config.data.usersessions.track_activity ? <td>{session.last_seen_at}</td> : null}
              <td>{session.is_current ? '⭐' : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
